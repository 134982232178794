<template>
  <el-main class="rh-medform">
    <form-fpregradcreate :routes="fprRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormFpregradcreate from "@/components/rh/formulaires/FormFpregradcreate";

export default {
  components: { "form-fpregradcreate": FormFpregradcreate },
  computed: {
    fprRoutes() {
      return {
        toedit: {
          name: "med_fpregrads_edit",
          params: {
            idmed: this.$route.params.idmed,
            idfpr: this.$store.state.fpr.fpregradLastCreatedId,
          },
        },
        tolist: {
          name: "med_fpregrads",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
